'use client';

import border from '@haaretz/l-border.macro';
import color from '@haaretz/l-color.macro';
import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import Button from '@haaretz/s-button';
import HtzLink from '@haaretz/s-htz-link';
import Icon from '@haaretz/s-icon';
import * as React from 'react';
import s9 from 'style9';

import type { LiveBlogItemFragment } from '@haaretz/s-fragments/LiveBlogItem';

const c = s9.create({
  item: {
    display: 'grid',
  },
  border: {
    ...border({
      color: color('neutral300'),
      side: 'top',
      style: 'solid',
      width: '4px',
      spacing: 6,
    }),
  },
  content: {
    position: 'relative',
  },
  notExpand: {
    ...merge(mq({ until: 's', value: { maxHeight: space(113), overflow: 'hidden' } })),
  },
  expand: {
    maxHeight: 'auto',
  },
  eclipse: {
    height: '5lh',
    position: 'absolute',
    insetInlineEnd: 0,
    bottom: 0,
    width: '100%',
    backgroundImage: `linear-gradient(to bottom, ${color('contentBg', { opacity: 0 })} 0%, ${color(
      'contentBg'
    )} 100%)` as string,
    ...merge(mq({ from: 's', value: { display: 'none' } })),
  },
  button: {
    justifySelf: 'start',
    marginTop: space(4),
    ...typesetter(-2),
    ...merge(mq({ from: 's', value: { display: 'none' } })),
  },
  fullArticle: {
    justifySelf: 'end',
    display: 'flex',
    alignItems: 'center',
  },
  fullArticleText: {
    marginInlineEnd: space(1),
    fontWeight: 700,
    ...typesetter(-2),
  },
  arrow: {
    fontSize: space(4),
    transform: 'rotate(180deg)',
  },
  featureItem: {
    borderRadius: radius('xLarge'),
    backgroundColor: color('neutral300'),
    padding: space(3),
    ...merge(mq({ from: 'l', value: { padding: space(4) } })),
  },
});

type LiveBlogItemWrapperProps = {
  itemId: LiveBlogItemFragment['itemId'];
  isFeatureItem: boolean;
  isItemWithoutBorder: boolean;
  fullArticleLink: LiveBlogItemFragment['fullArticleLink'];
  children: React.ReactNode;
};

const expandButtonText = fork({
  default: 'להרחבה',
  hdc: 'Expand',
});
const fullArticleText = fork({
  default: 'לכתבה המלאה',
  hdc: 'Read the full article',
});

export default function LiveBlogItemWrapper({
  itemId,
  fullArticleLink,
  isFeatureItem,
  isItemWithoutBorder,
  children,
}: LiveBlogItemWrapperProps) {
  const [open, setIsOpen] = React.useState(false);
  const [availableExpand, setAvailableExpand] = React.useState(false);
  const itemRef = React.useRef<HTMLDivElement | null>(null);
  React.useEffect(() => {
    if (itemRef.current?.clientHeight && itemRef.current?.clientHeight > 450) {
      setAvailableExpand(true);
    }
  }, []);

  return (
    <div
      className={s9(c.item, isFeatureItem ? c.featureItem : !isItemWithoutBorder && c.border)}
      id={itemId ?? undefined}
    >
      <div
        className={s9(c.content, availableExpand && !open ? c.notExpand : c.expand)}
        ref={itemRef}
      >
        {children}
        {availableExpand && !open && <div className={s9(c.eclipse)} />}
      </div>
      {availableExpand && (
        <Button
          onClick={() => {
            setIsOpen(true);
            setAvailableExpand(false);
          }}
          priority="secondary"
          styleExtend={[c.button]}
        >
          {expandButtonText}
        </Button>
      )}
      {fullArticleLink && (
        <HtzLink href={fullArticleLink} className={s9(c.fullArticle)}>
          <span className={s9(c.fullArticleText)}>{fullArticleText}</span>
          <Icon icon="arrow-right" styleExtend={[c.arrow]} />
        </HtzLink>
      )}
    </div>
  );
}
